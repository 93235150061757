export const SITE_NAME = 'Dental Story';
export const ACCESS_TOKEN = 'accessToken';
export const EMAIL_PLACEHOLDER = 'E-mail';
export const PASS_PLACEHOLDER = 'Пароль';
export const FIRST_NAME_PLACEHOLDER = 'Имя';
export const LAST_NAME_PLACEHOLDER = 'Фамилия';
export const MIDDLE_NAME_PLACEHOLDER = 'Отчество';
export const PHONE_PLACEHOLDER = 'Телефон';
export const INN_PLACEHOLDER = 'ИНН';
export const IIN_PLACEHOLDER = 'ИИН';
export const AGE_PLACEHOLDER = 'Возраст';
export const ROLE_PLACEHOLDER = 'Роль';
export const CLINIC_PLACEHOLDER = 'Стоматология';
export const ADDRESS_PLACEHOLDER = 'Адрес';
