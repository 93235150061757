import styles from './PatientsTable.module.scss';
import cx from 'clsx';
import { useState } from 'react';
import { ArrowupIcon } from 'components/shared/icons/ArrowupIcon';
import { ArrowdownIcon } from 'components/shared/icons/ArrowdownIcon';
import { Patient } from 'api';
import { getFullname } from 'utils/getFullname';
import { Paths } from 'domain/Paths';
import { useNavigate } from 'react-router-dom';

type SortDirection = 'ASC' | 'DESC';

interface ISortState {
    fio?: SortDirection;
    doctors?: SortDirection;
    totalRecords?: SortDirection;
}

interface IProps {
    className?: string;
    items: Patient[];
}

export const PatientsTable: React.FC<IProps> = ({ className = '', items = [] }) => {
    const [sort, setSort] = useState<ISortState>({});

    const sortHandler = (field: keyof ISortState) => (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setSort((prev) => ({ ...prev, [field]: prev[field] === 'ASC' ? 'DESC' : 'ASC' }));
    };

    return (
        <div className={cx({ [styles.root]: true, [className]: true })}>
            <table>
                <thead>
                    <tr>
                        <th style={{ minWidth: 268 }}>
                            <SortField name="ФИО" dir={sort.fio} onSort={sortHandler('fio')} />
                        </th>
                        <th style={{ minWidth: 115 }}>Телефон</th>
                        <th style={{ minWidth: 320 }}>
                            <SortField
                                name="Врачи"
                                dir={sort.doctors}
                                onSort={sortHandler('doctors')}
                            />
                        </th>
                        <th style={{ minWidth: 70 }}>
                            <SortField
                                name="Записей"
                                dir={sort.totalRecords}
                                onSort={sortHandler('totalRecords')}
                            />
                        </th>
                        <th style={{ minWidth: 200 }}>Дата рожд.</th>
                        <th style={{ minWidth: 100 }}>ИИН</th>
                        <th style={{ minWidth: 260 }}>Адрес</th>
                        <th style={{ minWidth: 200 }}>Номер карты</th>
                        <th style={{ minWidth: 80 }}>Оплачено</th>
                        <th style={{ minWidth: 80 }}>Долг</th>
                        <th style={{ minWidth: 80 }}>Переплата</th>
                        <th style={{ minWidth: 260 }}>Последний прием</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <PatientsRow key={item.id} patient={item} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

interface ISortFieldProps {
    name: string;
    dir?: SortDirection;
    onSort: (e: React.MouseEvent) => void;
}

export const SortField: React.FC<ISortFieldProps> = ({ name, dir, onSort }) => {
    return (
        <div className={styles.sortField}>
            <div className={styles.sortField__title}>{name}</div>
            <div className={styles.sortField__sortIcon} onClick={onSort}>
                {dir === 'ASC' ? <ArrowupIcon /> : <ArrowdownIcon />}
            </div>
        </div>
    );
};

interface IPatientsRowProps {
    patient: Patient;
    className?: string;
}

const PatientsRow: React.FC<IPatientsRowProps> = ({ patient, className }) => {
    const { id, first_name, last_name, middle_name } = patient;

    const navigate = useNavigate();

    const patientFullName = getFullname({
        firstName: first_name,
        lastName: last_name,
        middleName: middle_name,
    });

    if (!id) return null;

    const handleOpenPatienPage = () => {
        navigate(Paths.getPatientPathById(id));
    };

    return (
        <tr className={cx(styles.row, className)} onClick={handleOpenPatienPage}>
            <td>{patientFullName}</td>
            <td>{patient.phone}</td>
            <td>
                {patient.doctors?.map(({ last_name, first_name, middle_name, records }) => {
                    const name = getFullname({
                        firstName: first_name,
                        lastName: last_name,
                        middleName: middle_name,
                    });
                    return (
                        <div key={name}>
                            <span>{name}</span>{' '}
                            <span className={styles.recordCount}>({records})</span>
                        </div>
                    );
                })}
            </td>
            <td>{patient.total_records}</td>
            <td>{patient.birthday}</td>
            <td>{patient.inn}</td>
            <td>{patient.address}</td>
            <td>{patient.card_number}</td>
            <td>{patient.paid_sum}</td>
            <td>{patient.debt_sum}</td>
            <td>{patient.overpaid_sum}</td>
            <td>
                {/* TODO: добавить поле с именем последнего врача */}
                <div>Антонина Петрова</div>
                {/* TODO: добавить поле дата */}
                <div>01.10.2023</div>
                {/* TODO: добавить поле период времени */}
                <div>15:00 - 18:00</div>
            </td>
        </tr>
    );
};
