import { ContentLayout } from 'components/shared/ContentLayout/ContentLayout';
import styles from './ProfilPage.module.scss';
import { Button } from 'components/shared/Button/Button';
import { CardTitle } from 'components/shared/CardTitle/CardTitle';
import { Input } from 'components/shared/Input/Input';
import { useForm } from 'react-hook-form';
import { selectUser } from 'redux/selectors/userSelector';
import {
    FIRSTNAME_VALIDATE,
    LASTNAME_VALIDATE,
    MIDDLENAME_VALIDATE,
    PHONE_VALIDATE,
} from 'utils/validate';
import {
    CLINIC_PLACEHOLDER,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_PLACEHOLDER,
    LAST_NAME_PLACEHOLDER,
    MIDDLE_NAME_PLACEHOLDER,
    PHONE_PLACEHOLDER,
    ROLE_PLACEHOLDER,
} from 'variables/fieldVariables';
import { AvatarMini } from 'components/shared/AvatarMini/AvatarMini';
import { getFullname } from 'utils/getFullname';
import { useEffect } from 'react';

interface IFormState {
    lastname: string;
    firstname: string;
    middlename?: string;
    phone?: string;
}

export const ProfilPage = () => {
    const user = selectUser();

    const { register, watch, setValue, handleSubmit, formState } = useForm<IFormState>();

    const { errors } = formState;

    useEffect(() => {
        if (user?.employee) {
            setValue('firstname', user.employee?.first_name || '');
            setValue('lastname', user.employee?.last_name || '');
            setValue('middlename', user.employee?.middle_name);
            setValue('phone', user.employee?.phone?.toString());
        }
    }, [setValue, user?.employee]);

    // const { email, employee } = user;

    const fullname = getFullname({
        firstName: user?.employee?.first_name,
        lastName: user?.employee?.last_name,
        middleName: user?.employee?.middle_name,
    });

    const onSubmit = (data: IFormState) => {
        console.log(data);
    };

    return (
        <ContentLayout>
            <CardTitle>Профиль</CardTitle>
            <div className={styles.root}>
                <form className={styles.from} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className={styles.block_1}>
                        <div className={styles.block_1__left}>
                            <Input
                                type="text"
                                register={register('lastname', LASTNAME_VALIDATE)}
                                placeholder={LAST_NAME_PLACEHOLDER}
                                watchedValue={watch('lastname')}
                                width={300}
                                error={errors.lastname}
                                required
                            />
                            <Input
                                type="text"
                                register={register('firstname', FIRSTNAME_VALIDATE)}
                                placeholder={FIRST_NAME_PLACEHOLDER}
                                watchedValue={watch('firstname')}
                                width={300}
                                error={errors.firstname}
                                required
                            />
                            <Input
                                type="text"
                                register={register('middlename', MIDDLENAME_VALIDATE)}
                                placeholder={MIDDLE_NAME_PLACEHOLDER}
                                watchedValue={watch('middlename') || ''}
                                width={300}
                                error={errors.middlename}
                                required
                            />
                            <Input
                                type="tel"
                                register={register('phone', PHONE_VALIDATE)}
                                placeholder={PHONE_PLACEHOLDER}
                                watchedValue={watch('phone') || ''}
                                width={300}
                                error={errors.phone}
                                required
                            />
                        </div>
                        <div className={styles.block_1__right}>
                            <AvatarMini
                                className={styles.avatar}
                                name={fullname}
                                src={user?.employee?.photo ?? undefined}
                                size="extralarge"
                            />
                            <Input
                                type="text"
                                placeholder={EMAIL_PLACEHOLDER}
                                watchedValue={user?.email || ''}
                                width={300}
                                disabled
                                defaultValue={user?.email}
                            />
                            <Input
                                type="text"
                                placeholder={ROLE_PLACEHOLDER}
                                watchedValue={user?.employee?.role || ''}
                                width={300}
                                disabled
                                defaultValue={user?.employee?.role}
                            />
                            <Button type="button" use="link">
                                Сменить пароль
                            </Button>
                        </div>
                    </div>
                    <div className={styles.block_2}>
                        <Input
                            placeholder={CLINIC_PLACEHOLDER}
                            disabled
                            type="text"
                            watchedValue={user?.employee?.institution || ''}
                            width={730}
                            defaultValue={user?.employee?.institution}
                        />
                    </div>
                    <div className={styles.bottomPanel}>
                        <Button type="submit" width={210}>
                            Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        </ContentLayout>
    );
};
