import { IScheduleItem } from 'domain/schedule';
import { TagsEnum } from 'redux/api';
import { IPagedResult } from 'redux/models/core/PagedTypes';
import { BuildType } from 'redux/types';
import { DEFAULT_PAGE_SIZE } from 'variables/constants';

interface IScheduleRequest {
    date: string;
}

export const scheduleEndpoints = (builder: BuildType) => {
    return {
        /** Запрос расписания по дате */
        getScheduleByDate: builder.query<IPagedResult<IScheduleItem>, IScheduleRequest>({
            query: (params) => ({
                url: '/clinic/schedule/',
                method: 'GET',
                params: { page_size: DEFAULT_PAGE_SIZE, ...params },
            }),
            providesTags: [TagsEnum.Schedule],
        }),
    };
};
