import { ContentLayout } from 'components/shared/ContentLayout/ContentLayout';
import styles from './NotfoundPage.module.scss';
import cx from 'clsx';

interface IProps {
    className?: string;
}

export const NotfoundPage: React.FC<IProps> = ({ className = '' }) => {
    return (
        <ContentLayout className={cx({ [styles.root]: true, [className]: true })}>
            <div className={styles.errorMessage}>404</div>
        </ContentLayout>
    );
};
