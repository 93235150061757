import { Patient } from 'api';
import { TagsEnum, api } from 'redux/api';
import { IPagedRequest, IPagedResult } from 'redux/models/core/PagedTypes';
import { store } from 'redux/store';
import { BuildType } from 'redux/types';
import { phoneForRequest } from 'utils/phoneForRequest';
import { DEFAULT_PAGE_SIZE } from 'variables/constants';

export const patientEndpoints = (builder: BuildType) => {
    return {
        /** Запрос пациентов */
        getPatientList: builder.query<IPagedResult<Patient>, IPagedRequest>({
            query: (params) => ({
                url: '/clinic/patient/',
                method: 'GET',
                params: { page_size: DEFAULT_PAGE_SIZE, ...params },
            }),
            providesTags: [TagsEnum.Patient],
        }),
        /** Запрос пациента по id */
        getPatientById: builder.query<Patient, string>({
            query: (id) => ({ url: `/clinic/patient/${id}`, method: 'GET' }),
            providesTags: [TagsEnum.Patient],
        }),
        /** Изменения данных пациента по id */
        setPatientById: builder.mutation<Patient, { id: string; body: Patient }>({
            query: ({ id, body }) => ({
                url: `/clinic/patient/${id}/`,
                method: 'PATCH',
                body: {
                    ...body,
                    phone: phoneForRequest(body.phone || ''),
                },
            }),
            invalidatesTags: [TagsEnum.Patient],
        }),
    };
};

export function clearPatientData() {
    store.dispatch(api.util.invalidateTags([TagsEnum.Patient]));
}
