import * as React from 'react';

interface Props {
    className?: string;
}

export const ArrowRightIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            className={className}
        >
            <path
                fillRule="evenodd"
                d="M.456 14.82L6.553 9 .456 3.18c-.613-.585-.613-1.53 0-2.115a1.62 1.62 0 0 1 2.216 0L9.884 7.95c.613.585.613 1.53 0 2.115L2.671 16.95a1.62 1.62 0 0 1-2.216 0c-.597-.585-.613-1.545 0-2.13z"
                fill="currentColor"
            />
        </svg>
    );
};
