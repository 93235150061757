import * as React from 'react';

interface Props {
    className?: string;
    scaleable?: boolean;
    width?: number;
    height?: number;
}

export const ProfileIcon: React.FC<Props> = ({
    className,
    scaleable = false,
    width = 24,
    height = 24,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={scaleable ? undefined : width}
            height={scaleable ? undefined : height}
            viewBox="0 0 22.5 24"
            fill="currentColor"
            className={className}
        >
            <path
                fill="currentColor"
                d="M11.5 12a6.22 6.22 0 0 0 3.403-1.011 6.03 6.03 0 0 0 2.256-2.693c.464-1.096.585-2.303.349-3.467s-.82-2.233-1.676-3.072A6.17 6.17 0 0 0 12.695.115a6.24 6.24 0 0 0-3.539.341 6.1 6.1 0 0 0-2.749 2.21C5.734 3.653 5.375 4.813 5.375 6a5.94 5.94 0 0 0 1.794 4.243A6.19 6.19 0 0 0 11.5 12zm0-10.286a4.44 4.44 0 0 1 2.431.722c.719.471 1.28 1.14 1.611 1.923s.418 1.645.249 2.476a4.26 4.26 0 0 1-1.197 2.194c-.612.599-1.392 1.008-2.24 1.173a4.46 4.46 0 0 1-2.528-.244c-.799-.324-1.483-.874-1.963-1.578S7.125 6.848 7.125 6a4.24 4.24 0 0 1 1.281-3.03A4.42 4.42 0 0 1 11.5 1.714zm.875 12h-1.75c-2.553 0-5.001.993-6.806 2.762S1 20.642 1 23.143a.85.85 0 0 0 .256.606c.164.161.387.251.619.251h19.25c.232 0 .455-.09.619-.251a.85.85 0 0 0 .256-.606c0-2.501-1.014-4.899-2.819-6.667s-4.253-2.762-6.806-2.762zm-9.572 8.571a7.66 7.66 0 0 1 2.575-4.891 7.97 7.97 0 0 1 5.248-1.966h1.75a7.97 7.97 0 0 1 5.248 1.966c1.443 1.265 2.36 3.006 2.575 4.891H2.803z"
            />
        </svg>
    );
};
