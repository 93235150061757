import * as React from 'react';

interface Props {
    className?: string;
    scaleable?: boolean;
    width?: number;
    height?: number;
}

export const StaffIcon: React.FC<Props> = ({
    className,
    scaleable = false,
    width = 24,
    height = 24,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={scaleable ? undefined : width}
            height={scaleable ? undefined : height}
            viewBox="0 0 24 24"
            fill="currentColor"
            className={className}
        >
            <path d="M12 16.8a4.8 4.8 0 0 1-3.394-8.194A4.8 4.8 0 0 1 16.8 12a4.8 4.8 0 0 1-4.8 4.8zm0-8a3.2 3.2 0 0 0-2.262 5.462A3.2 3.2 0 0 0 15.2 12 3.2 3.2 0 0 0 12 8.8zM16 24H8a3.2 3.2 0 0 1-3.2-3.2v-2.04a.8.8 0 0 1 .504-.736l2.912-1.168a.8.8 0 0 1 1.069.439.8.8 0 0 1-.485 1.049l-2.4.96V20.8A1.6 1.6 0 0 0 8 22.4h8a1.6 1.6 0 0 0 1.6-1.6v-1.496l-2.4-.96a.8.8 0 1 1 .592-1.488l2.912 1.168a.8.8 0 0 1 .504.736v2.04A3.2 3.2 0 0 1 16 24z" />
            <path d="M6.4 8a4 4 0 0 1-2.829-6.828A4 4 0 0 1 5.62.077a4 4 0 0 1 2.311.228 4 4 0 0 1 1.297 6.523A4 4 0 0 1 6.4 8zm0-6.4a2.4 2.4 0 0 0-1.697 4.097A2.4 2.4 0 0 0 8.8 4a2.4 2.4 0 0 0-2.4-2.4zm-.8 13.112H3.2a3.2 3.2 0 0 1-3.2-3.2v-1.4a.8.8 0 0 1 .138-.449.8.8 0 0 1 .366-.295l2.552-1a.8.8 0 0 1 1.032.432.8.8 0 0 1-.448 1.04l-2.04.816v.856a1.6 1.6 0 0 0 1.6 1.6h2.4a.8.8 0 1 1 0 1.6zM17.6 8a4 4 0 0 1-2.829-6.828A4 4 0 0 1 19.13.305 4 4 0 0 1 17.6 8zm0-6.4a2.4 2.4 0 0 0-1.697 4.097A2.4 2.4 0 0 0 20 4a2.4 2.4 0 0 0-2.4-2.4zm3.2 13.112h-2.4a.8.8 0 1 1 0-1.6h2.4a1.6 1.6 0 0 0 1.6-1.6v-.856l-2.04-.8a.8.8 0 0 1-.448-1.04.8.8 0 0 1 1.032-.456l2.552 1a.8.8 0 0 1 .504.744v1.4a3.2 3.2 0 0 1-3.2 3.208z" />
        </svg>
    );
};
