export const MONTHS_RU = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const YEARS = [...Array(100).keys()].map((i) => 2000 + i);

export const WEEK_DAYS_RU_SHORT = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const DAYS_IN_WEEK = 7;

enum Month {
    January = 0,
    February = 1,
    March = 2,
    April = 3,
    May = 4,
    June = 5,
    July = 6,
    August = 7,
    Septemeber = 8,
    October = 9,
    November = 10,
    December = 11,
}

enum DayOfWeek {
    Monday = 0,
    Tuesday = 1,
    Wednesday = 2,
    Thursday = 3,
    Friday = 4,
    Saturday = 5,
    Sunday = 6,
}

export const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getDaysInMonth = (date: Date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const daysInMonth = DAYS_IN_MONTH[month];

    if (isLeapYear(year) && month === Month.February) {
        return daysInMonth + 1;
    } else {
        return daysInMonth;
    }
};

export const getDateOfWeek = (date: Date) => {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === DayOfWeek.Monday) return 6;
    return dayOfWeek - 1;
};

export const getMonthData = (year: number, month: number) => {
    const result: Array<Array<Date | undefined>> = [];
    const date = new Date(year, month);
    const daysInMonth = getDaysInMonth(date);
    const monthStartsOn = getDateOfWeek(date);
    let day = 1;

    for (let i = 0; i < (daysInMonth + monthStartsOn) / DAYS_IN_WEEK; i++) {
        result[i] = [];

        for (let j = 0; j < DAYS_IN_WEEK; j++) {
            if ((i === 0 && j < monthStartsOn) || day > daysInMonth) {
                if (day <= 1) {
                    result[i][j] = new Date(year, month, day - (monthStartsOn - j));
                } else {
                    result[i][j] = new Date(year, month, day++);
                }
            } else {
                result[i][j] = new Date(year, month, day++);
            }
        }
    }

    return result;
};
