import styles from './Header.module.scss';
import { NavLink } from 'react-router-dom';
import { AvatarMini } from '../AvatarMini/AvatarMini';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { LogoIcon } from '../icons/LogoIcon';
import { SwitchThemeToggle } from '../SwitchThemeToggle/SwitchThemeToggle';
import { selectUser } from 'redux/selectors/userSelector';

export const Header = () => {
    const user = selectUser()?.employee;

    const fullName = user?.first_name ? `${user.first_name} ${user.last_name}` : undefined;

    const photo = user?.photo || undefined;

    return (
        <header className={styles.backplate}>
            <div className={styles.root}>
                <NavLink className={styles.logo} to="/">
                    <LogoIcon className={styles.logo__icon} />
                </NavLink>
                <SwitchThemeToggle className={styles.themeToggle} />
                <DropdownMenu className={styles.dropdownMenu} position="right">
                    <AvatarMini name={fullName} src={photo} />
                </DropdownMenu>
            </div>
        </header>
    );
};
