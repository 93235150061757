import { CalendarIcon } from 'components/shared/icons/CalendarIcon';
import { Paths } from './Paths';
import { StaffIcon } from 'components/shared/icons/StaffIcon';
import { ProfileIcon } from 'components/shared/icons/ProfileIcon';
import { PatientIcon } from 'components/shared/icons/PatientIcon';

export type MenuItemType = {
    caption: string;
    link: string;
    icon?: React.ReactNode;
    subItems?: MenuItemType[];
    handler?: () => void;
};

export const LEFT_MENU_ITEMS: MenuItemType[] = [
    { caption: 'Расписание', link: Paths.schedule, icon: <CalendarIcon /> },
    { caption: 'Пациенты', link: Paths.patients, icon: <PatientIcon /> },
    { caption: 'Сотрудники', link: Paths.staff, icon: <StaffIcon /> },
    {
        caption: 'Профиль',
        link: Paths.profile,
        icon: <ProfileIcon />,
        // subItems: [
        //     { caption: 'Первичный осмотр', link: Paths.primaryInspection },
        //     { caption: 'История болезни', link: Paths.diseaseHistory },
        //     { caption: 'Лабораторные работы', link: Paths.laboratoryWorks },
        // ],
    },
    // { caption: 'Отчёты', link: Paths.reports, icon: <ReportIcon /> },
];
