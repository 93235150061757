import { IScheduleParams } from 'domain/schedule';

export const DEFAULT_PAGE_SIZE = 20;

export const SCHEDULE_PARAMS: IScheduleParams = {
    startTime: { hours: 6, minutes: 0 },
    endTime: { hours: 20, minutes: 0 },
    step: 30,
};

export const CHAIRS = [1, 2, 3, 4, 5];

export const COLORS = {
    Gold: '#FFD700',
    Green: '#00FF00',
    SkyBlue: '#87CEEB',
    Pink: '#FF69B4',
    DarkTurquoise: '#00CED1',
    Orange: '#FFA500',
    Purple: '#8A2BE2',
    LimeGreen: '#32CD32',
    Coral: '#FF6347',
    SlateBlue: '#6A5ACD',
    Turquoise: '#20B2AA',
    DarkSalmon: '#E9967A',
    RoyalBlue: '#4169E1',
    OrangeRed: '#FF4500',
    MediumPurple: '#9370DB',
};
