import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import styles from './MainLayout.module.scss';
import { Sidebar } from '../Sidebar/Sidebar';
import { Header } from '../Header/Header';
import { useAuthInfo } from 'hooks/useAuthInfo';
import cx from 'clsx';
import { useAppSelector } from 'redux/hooks';
import { isDarkTheme } from 'redux/slices/appSlice';
import { Outlet } from 'react-router-dom';
import { Paths } from 'domain/Paths';
import { Navigate, useLocation } from 'react-router-dom';

export const MainLayout = () => {
    const { logged } = useAuthInfo();
    const isDark = useAppSelector(isDarkTheme);

    return (
        <div className={cx({ [styles.root]: true, [styles.root_isDark]: isDark })}>
            <Redirects />
            <Header />
            <ErrorBoundary>
                <div className={cx({ [styles.backplate]: true })}>
                    {logged && <Sidebar className={styles.sidebar} />}
                    <Outlet />
                </div>
            </ErrorBoundary>
        </div>
    );
};

const Redirects = ({ ...rest }) => {
    const location = useLocation();

    /** Убираем слеш с конца урла */
    if (location.pathname.match('/.*/$')) {
        return (
            <Navigate
                replace
                {...rest}
                to={{ pathname: location.pathname.replace(/\/+$/, ''), search: location.search }}
            />
        );
        /** Перенаправляем со страницы /auth на страницу /auth/sign-in */
    } else if (location.pathname === Paths.auth) {
        return (
            <Navigate
                replace
                {...rest}
                to={{ pathname: Paths.authSignin, search: location.search }}
            />
        );
    } else return null;
};
