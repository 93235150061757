import { USER_ROLES } from 'redux/models/authModels';
import { IUser } from 'redux/models/userModels';
import { uuidv4 } from 'utils/guid';
import { COLORS } from 'variables/constants';

export const doctorMock = (props?: Partial<IUser>): IUser => ({
    id: uuidv4(),
    first_name: 'Иван',
    last_name: 'Иванов',
    middle_name: 'Иванович',
    phone: '+79008007061',
    email: 'ivanov@email.ru',
    clinic_info: [{ chair: 1, clinic_id: uuidv4(), color: COLORS.Coral, role: USER_ROLES.DOCTOR }],
    ...props,
});

export const doctorListMock: IUser[] = [
    doctorMock(),
    doctorMock({
        first_name: 'Петр',
        last_name: 'Петров',
        middle_name: 'Петрович',
        clinic_info: [
            { chair: 2, clinic_id: uuidv4(), color: COLORS.DarkSalmon, role: USER_ROLES.DOCTOR },
        ],
    }),
    doctorMock({
        first_name: 'Елена',
        last_name: 'Полякова',
        middle_name: 'Михайловна',
        clinic_info: [
            { chair: 3, clinic_id: uuidv4(), color: COLORS.DarkTurquoise, role: USER_ROLES.DOCTOR },
        ],
    }),
    doctorMock({
        first_name: 'Александр',
        last_name: 'Александров',
        middle_name: 'Александрович',
        clinic_info: [
            { chair: 4, clinic_id: uuidv4(), color: COLORS.Green, role: USER_ROLES.DOCTOR },
        ],
    }),
];
