import * as React from 'react';
import styles from './Toggle.module.scss';
import cx from 'clsx';
import { isDarkTheme } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/hooks';

export type TogglePositionType = 'left' | 'right';
interface Props {
    position: TogglePositionType;
    items?: string[];
    className?: string;
    onChange: () => void;
}

export const Toggle: React.FC<Props> = ({ position, className, onChange }) => {
    const isDark = useAppSelector(isDarkTheme);

    return (
        <div className={cx(styles.root, isDark && styles.root__dark, className)} onClick={onChange}>
            <div className={styles[`root_${position}`]}></div>
            <div className={styles.root__checkbox}></div>
        </div>
    );
};
