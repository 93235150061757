import { BreadcrumbProps } from 'components/shared/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/shared/Button/Button';
import { ContentLayout } from 'components/shared/ContentLayout/ContentLayout';
import { Paths } from 'domain/Paths';
import styles from './NewPatientPage.module.scss';
import Select from 'react-select';

export const NewPatientPage = () => {
    const breadcrumbs: BreadcrumbProps[] = [
        { caption: 'Пациенты', link: Paths.patients },
        { caption: '' },
    ];

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // TODO: вызов метода для добавления пациента
    };

    return (
        <ContentLayout breadcrumbs={breadcrumbs} isLoading={false}>
            <div className={styles.backplate}>
                <div className={styles.root}>
                    <div className={styles.title}>Добавление нового пациента</div>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        {/* <Input
                            placeholder={FIRST_NAME_PLACEHOLDER}
                            value={form.firstName}
                            onChange={handleInputChange('firstName')}
                            type="text"
                            watch={watch('firstName')}
                        />
                        <Input
                            placeholder={LAST_NAME_PLACEHOLDER}
                            value={form.lastName}
                            onChange={handleInputChange('lastName')}
                            type="text"
                            watch={watch('lastName')}
                        />
                        <Input
                            placeholder={MIDDLE_NAME_PLACEHOLDER}
                            value={form.middleName || ''}
                            onChange={handleInputChange('middleName')}
                            type="text"
                            watch={watch('middleName')}
                        />
                        <Input
                            placeholder={AGE_PLACEHOLDER}
                            value={`${form.age}`}
                            onChange={handleInputChange('age')}
                            type="number"
                            watch={watch('age')}
                        /> */}
                        <div className={styles.sex}>
                            <Select
                                options={[
                                    { value: 'woomen', label: 'Женский' },
                                    { value: 'men', label: 'Мужской' },
                                ]}
                                // onChange={handleChangeSex}
                                className={styles.sex__select}
                                placeholder="Выберите пол"
                            />
                        </div>
                        <div className={styles.bottomPanel}>
                            <Button type="submit">Добавить</Button>
                        </div>
                    </form>
                </div>
            </div>
        </ContentLayout>
    );
};
