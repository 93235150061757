import { login, logout, useAuth } from 'redux/token';

export const useAuthInfo = () => {
    const [logged, session] = useAuth();

    const isDevMode = false;

    return {
        logged: logged || isDevMode,
        session,
        login,
        logout,
    };
};
