import * as React from 'react';
import styles from './Input.module.scss';
import cx from 'clsx';
import { InputHTMLAttributes } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useAppSelector } from 'redux/hooks';
import { isDarkTheme } from 'redux/slices/appSlice';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    register?: UseFormRegisterReturn<string>;
    error?: FieldError;
    watchedValue: string;
}

export const Input: React.FC<Props> = ({ register, error, watchedValue, ...props }) => {
    const rootStyles = { width: props.width };
    const type = props.type || 'text';
    const isDark = useAppSelector(isDarkTheme);
    const isError = !!error;
    const hasLabel = !!props.placeholder;
    const hasText = !!watchedValue;

    return (
        <div
            className={cx({
                [styles.root]: true,
                [styles.root_hasError]: isError,
                [styles.root_hasText]: hasText,
                [styles.root_isDark]: isDark,
                [styles.root_isDisabled]: props.disabled,
            })}
            style={rootStyles}
        >
            <div className={styles.root__wrapper}>
                <input
                    type={type}
                    className={cx({
                        [styles.root__input]: true,
                        [props.className || '']: true,
                        [styles[type]]: true,
                    })}
                    {...register}
                    {...props}
                    placeholder={undefined}
                />
                {!!hasLabel && <div className={styles.root__label}>{props.placeholder}</div>}
                <div className={styles.root__divider} />
            </div>
            <div className={styles.root__error}>{error?.message}</div>
        </div>
    );
};
