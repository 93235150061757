import { Route, Routes } from 'react-router-dom';
import { MainPage } from './MainPage/MainPage';
import { AuthorizationPage } from './Auth/AuthorizationPage/AuthorizationPage';
import { PatientDetailPage } from './Patients/PatientDetailPage/PatientDetailPage';
import { NewPatientPage } from './Patients/NewPatientPage/NewPatientPage';
import { ToothDetailPage } from './Patients/ToothDetailPage/ToothDetailPage';
import { NewToothPage } from './Patients/NewToothPage/NewToothPage';
import { Paths } from 'domain/Paths';
import { ProtectedRoute } from 'utils/ProtectedRoute';
import { useAuthInfo } from 'hooks/useAuthInfo';
import { PatientsPage } from './Patients/PatientsPage/PatientsPage';
import { AuthMain } from './Auth/AuthMain/AuthMain';
import { useEffect, useState } from 'react';
import { useLazyGetUserQuery } from 'redux/api';
import { MainLayout } from './shared/MainLayout/MainLayout';
import { NotfoundPage } from './NotfoundPage/NotfoundPage';
import { ProfilPage } from './ProfilPage/ProfilPage';
import { SchedulePage } from './SchedulePage/SchedulePage';

export const AppRouter = () => {
    const { logged } = useAuthInfo();

    const [isLoading, setIsLoading] = useState(true);

    const [getCurrentUser] = useLazyGetUserQuery();

    useEffect(() => {
        if (logged) getCurrentUser();
        setTimeout(() => setIsLoading(false));
    }, [getCurrentUser, logged]);

    if (isLoading) {
        return null;
    }

    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route path={Paths.mainPage} element={<ProtectedRoute isLogged={logged} />}>
                    <Route index element={<MainPage />} />
                    <Route path={Paths.schedule} element={<SchedulePage />} />
                    <Route path={Paths.profile} element={<ProfilPage />} />
                    <Route path={Paths.patients} element={<PatientsPage />} />
                    <Route path={Paths.patient} element={<PatientDetailPage />} />
                    <Route path={Paths.newPatient} element={<NewPatientPage />} />
                    <Route path={Paths.tooth} element={<ToothDetailPage />} />
                    <Route path={Paths.newTooth} element={<NewToothPage />} />
                </Route>
                <Route path={Paths.auth} element={<AuthMain />} />
                <Route path={Paths.authSignin} element={<AuthorizationPage />} />
                <Route path="*" element={<NotfoundPage />} />
            </Route>
        </Routes>
    );
};
