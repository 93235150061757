import { Button } from '../Button/Button';
import styles from './Calendar.module.scss';
import cx from 'clsx';
import { MONTHS_RU, WEEK_DAYS_RU_SHORT, YEARS, getMonthData } from './calendarHelpers';
import { useEffect, useState } from 'react';
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';
import { OptionType, Select } from '../Select/Select';

interface IDataState {
    date: Date;
    currentDate: Date;
    selectedDate: Date | null;
    // selectedYear: OptionType;
    // selectedMonth: OptionType;
}

interface IProps {
    className?: string;
    date?: Date;
    onChange: (date: Date) => void;
}

export const Calendar: React.FC<IProps> = ({ date = new Date(), onChange, className = '' }) => {
    const [data, setData] = useState<IDataState>({
        date,
        currentDate: new Date(),
        selectedDate: new Date(),
        // selectedMonth: { title: MONTHS_RU[date.getMonth()], value: date.getMonth() },
        // selectedYear: { title: `${date.getFullYear()}`, value: date.getFullYear() },
    });

    useEffect(() => {
        if (data.selectedDate) {
            onChange(data.selectedDate);
        }
    }, [onChange, data.selectedDate]);

    const monthData = getMonthData(data.date.getFullYear(), data.date.getMonth());

    const handlePrevMonth = () => {
        const { date } = data;
        const newDate = new Date(date.getFullYear(), date.getMonth() - 1);
        setData((p) => ({ ...p, date: newDate }));
    };

    const handleNextMonth = () => {
        const { date } = data;
        const newDate = new Date(date.getFullYear(), date.getMonth() + 1);
        setData((p) => ({ ...p, date: newDate }));
    };

    const handleSelectMonth = (value: OptionType['value']) => {
        const date = new Date(data.date.getFullYear(), +value);
        setData((p) => ({ ...p, date }));
    };

    const handleSelectYear = (value: OptionType['value']) => {
        const date = new Date(+value, data.date.getMonth());
        setData((p) => ({ ...p, date }));
    };

    const handleSelectDate = (date: Date) => {
        setData((p) => ({ ...p, selectedDate: date }));
    };

    const isDaySelected = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const { selectedDate } = data;
        return (
            year === selectedDate?.getFullYear() &&
            month === selectedDate.getMonth() &&
            day === selectedDate.getDate()
        );
    };

    const isOtherMonth = (date: Date) => {
        return date.getMonth() !== data.date.getMonth();
    };

    return (
        <div className={cx({ [styles.root]: true, [className]: true })}>
            <div className={styles.root__nav}>
                <Button use="link" icon={<ArrowLeftIcon />} onClick={handlePrevMonth} />
                <Select
                    selected={{
                        title: MONTHS_RU[data.date.getMonth()],
                        value: data.date.getMonth(),
                    }}
                    options={MONTHS_RU.map((m, i) => ({ title: m, value: i }))}
                    onChange={handleSelectMonth}
                    width={115}
                />
                <Select
                    selected={{
                        title: `${data.date.getFullYear()}`,
                        value: data.date.getFullYear(),
                    }}
                    options={YEARS.map((m, i) => ({ title: `${m}`, value: m }))}
                    onChange={handleSelectYear}
                    width={90}
                />
                <Button use="link" icon={<ArrowRightIcon />} onClick={handleNextMonth} />
            </div>
            <div className={styles.root__calendar}>
                <table>
                    <thead>
                        <tr>
                            {WEEK_DAYS_RU_SHORT.map((i) => (
                                <th key={i}>{i}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {monthData.map((i, key) => (
                            <tr key={key}>
                                {i.map((j, key) => {
                                    if (j) {
                                        return (
                                            <td
                                                key={key}
                                                onClick={() => handleSelectDate(j)}
                                                className={cx({
                                                    [styles.root_isSelected]: isDaySelected(j),
                                                    [styles.root_isOtherMonth]: isOtherMonth(j),
                                                })}
                                            >
                                                {j.getDate()}
                                            </td>
                                        );
                                    } else {
                                        return <td key={key} />;
                                    }
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
