import * as React from 'react';

interface Props {
    className?: string;
    scaleable?: boolean;
    width?: number;
    height?: number;
}

export const CalendarIcon: React.FC<Props> = ({
    className,
    scaleable = false,
    width = 24,
    height = 24,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={scaleable ? undefined : width}
            height={scaleable ? undefined : height}
            viewBox="0 0 24 24"
            fill="none"
            className={className}
        >
            <path
                d="M4.8 15h1.6c.884 0 1.6-.672 1.6-1.5V12c0-.829-.716-1.5-1.6-1.5H4.8c-.884 0-1.6.671-1.6 1.5v1.5c0 .828.716 1.5 1.6 1.5zm0-3h1.6v1.5H4.8V12zm12.8 9h1.6c.884 0 1.6-.671 1.6-1.5V18c0-.829-.716-1.5-1.6-1.5h-1.6c-.883 0-1.6.671-1.6 1.5v1.5c0 .829.716 1.5 1.6 1.5zm0-3h1.6v1.5h-1.6V18zm0-3h1.6c.884 0 1.6-.672 1.6-1.5V12c0-.829-.716-1.5-1.6-1.5h-1.6c-.883 0-1.6.671-1.6 1.5v1.5c0 .828.716 1.5 1.6 1.5zm0-3h1.6v1.5h-1.6V12zm3.2-10.5h-3.2V.75c0-.414-.358-.75-.8-.75s-.8.336-.8.75v.75H8V.75C8 .336 7.642 0 7.2 0s-.8.336-.8.75v.75H3.2C1.433 1.5 0 2.843 0 4.5V21c0 1.657 1.433 3 3.2 3h17.6c1.767 0 3.2-1.343 3.2-3V4.5c0-1.657-1.433-3-3.2-3zM22.4 21c0 .829-.716 1.5-1.6 1.5H3.2c-.884 0-1.6-.672-1.6-1.5V7.5h20.8V21h0zm0-15H1.6V4.5c0-.829.716-1.5 1.6-1.5h3.2v.75c0 .414.358.75.8.75s.8-.336.8-.75V3h8v.75c0 .414.358.75.8.75s.8-.336.8-.75V3h3.2c.884 0 1.6.671 1.6 1.5V6zM11.2 21h1.6c.884 0 1.6-.671 1.6-1.5V18c0-.829-.716-1.5-1.6-1.5h-1.6c-.883 0-1.6.671-1.6 1.5v1.5c0 .829.716 1.5 1.6 1.5zm0-3h1.6v1.5h-1.6V18zm0-3h1.6c.884 0 1.6-.672 1.6-1.5V12c0-.829-.716-1.5-1.6-1.5h-1.6c-.883 0-1.6.671-1.6 1.5v1.5c0 .828.716 1.5 1.6 1.5zm0-3h1.6v1.5h-1.6V12zm-6.4 9h1.6c.884 0 1.6-.671 1.6-1.5V18c0-.829-.716-1.5-1.6-1.5H4.8c-.884 0-1.6.671-1.6 1.5v1.5c0 .829.716 1.5 1.6 1.5zm0-3h1.6v1.5H4.8V18z"
                fill="currentColor"
            />
        </svg>
    );
};
