/** Приводит номер телефона к формату 79999999999 */
export const phoneForRequest = (phone: string) => {
  if (/\s/g.test(phone)) {
    phone = phone.replace(/\s/g, '');
  }

  if (/\+/g.test(phone)) {
    phone = phone.replace(/\+/g, '');
  }

  return phone;
};
