import { useState } from 'react';
import styles from './Sidebar.module.scss';
import cx from 'clsx';
import { ArrowdownIcon } from '../icons/ArrowdownIcon';
import { ArrowupIcon } from '../icons/ArrowupIcon';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { LEFT_MENU_ITEMS, MenuItemType } from 'domain/menu';
import { Card } from 'components/shared/Card/Card';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { isDarkTheme, isSidebarExpanded, toggleSidebar } from 'redux/slices/appSlice';
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';
import { Button } from '../Button/Button';

interface IProps {
    className?: string;
}

export const Sidebar: React.FC<IProps> = ({ className = '' }) => {
    const dispatch = useAppDispatch();

    const isExpanded = useAppSelector(isSidebarExpanded);

    const onToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    return (
        <Card
            className={cx({
                [styles.root]: true,
                [className]: true,
                [styles.root_isExpanded]: isExpanded,
            })}
            noPadding
        >
            <div className={styles.topNav}>
                <Button
                    icon={isExpanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                    use="link"
                    className={styles.showHideBtn}
                    onClick={onToggleSidebar}
                />
            </div>
            <div className={styles.menu}>
                {LEFT_MENU_ITEMS.map((v) => (
                    <MenuItem value={v} key={v.caption} minimized={!isExpanded} />
                ))}
            </div>
        </Card>
    );
};

interface IMenuItemProps {
    value: MenuItemType;
    minimized: boolean;
}

const MenuItem: React.FC<IMenuItemProps> = ({ value, minimized }) => {
    const navigate = useNavigate();

    const isDark = useAppSelector(isDarkTheme);

    const { caption, link, icon, subItems } = value;

    /** 0 - состояние при загрузке страницы
     *  1 - состояние после раскрытия
     *  2 - состояние после скрытия
     */
    const [expand, setExpand] = useState<0 | 1 | 2>(0);

    const hasSubmenu = !!subItems?.length;

    const handleClick = () => {
        if (hasSubmenu) {
            setExpand(expand === 1 ? 2 : 1);
            return;
        }
        navigate(link);
    };

    const { pathname } = useLocation();

    const isCurrent = matchPath(pathname, link);

    return (
        <div
            className={cx({
                [styles.menu__item]: true,
                [styles.menu__item_isCurrent]: isCurrent,
                [styles.menu__item_isDark]: isDark,
            })}
        >
            <div className={styles.title} onClick={handleClick}>
                <div className={styles.title__icon}>{icon}</div>
                <div className={minimized ? styles.title__caption_hidden: styles.title__caption }>{caption}</div>
                {hasSubmenu && (expand === 1 ? <ArrowupIcon /> : <ArrowdownIcon />)}
            </div>
            {hasSubmenu && (
                <div
                    className={cx({
                        [styles.submenu]: true,
                        [styles.submenu_expand]: expand === 1,
                        [styles.submenu_hide]: expand === 2,
                    })}
                >
                    {subItems?.map((v) => {
                        const handleClick = () => navigate(v.link);
                        return (
                            <div
                                key={v.caption}
                                className={styles.submenu__item}
                                onClick={handleClick}
                            >
                                {v.caption}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
