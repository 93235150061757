import { TokenObtainPair } from 'api/models/TokenObtainPair';
import { TokenRefresh } from 'api/models/TokenRefresh';
import { TagsEnum } from 'redux/api';
import { BuildType } from 'redux/types';

export const authEndpoints = (builder: BuildType) => {
    return {
        /** Авторизация */
        authSignin: builder.mutation<TokenRefresh, TokenObtainPair>({
            query: (body) => ({ url: '/auth/login/', body, method: 'POST' }),
            invalidatesTags: [TagsEnum.Auth],
        }),
    };
};
