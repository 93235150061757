import { Patient } from 'api';
import { guid } from 'utils/guid';

export const patientMock = (props?: Partial<Patient>): Patient => ({
    id: guid(),
    first_name: 'Григорий',
    last_name: 'Константинопольский',
    middle_name: 'Михайлович',
    phone: '+79998887766',
    doctors: [
        { first_name: 'Антонина', last_name: 'Петрова', middle_name: 'Михайловна', records: 2 },
        { first_name: 'Алексей', last_name: 'Власов', middle_name: 'Сергеевич', records: 1 },
    ],
    total_records: 3,
    birthday: '17.10.2008',
    inn: '760724300757',
    address: 'Мангистауская область г.Актау, 1 мкр. д.1, кв.123',
    card_number: '123456',
    paid_sum: 100500,
    debt_sum: 100500,
    overpaid_sum: 100500,
    last_reception: {
        doctor: { first_name: 'Сергей', last_name: 'Сергеев', middle_name: 'Сергеевич' },
        end_datetime: '2012-01-26T13:00:00.417-07:00',
        start_datetime: '2012-01-26T15:00:00.417-07:00',
    },
    ...props,
});

export const patientListMock: Patient[] = [
    patientMock({
        first_name: 'Григорий',
        last_name: 'Константинопольский',
        middle_name: 'Михайлович',
        phone: '+79998887766',
    }),
    patientMock({
        first_name: 'Анна',
        last_name: 'Иванова',
        middle_name: 'Сергеевна',
        phone: '+79123456789',
    }),
    patientMock({
        first_name: 'Дмитрий',
        last_name: 'Смирнов',
        middle_name: 'Александрович',
        phone: '+79887776655',
    }),
    patientMock({
        first_name: 'Елена',
        last_name: 'Петрова',
        middle_name: 'Владимировна',
        phone: '+79997776644',
    }),
    patientMock({
        first_name: 'Иван',
        last_name: 'Федоров',
        middle_name: 'Николаевич',
        phone: '+79668887733',
    }),
    patientMock({
        first_name: 'Ольга',
        last_name: 'Соколова',
        middle_name: 'Дмитриевна',
        phone: '+79552234455',
    }),
    patientMock({
        first_name: 'Максим',
        last_name: 'Васильев',
        middle_name: 'Павлович',
        phone: '+79996665544',
    }),
    patientMock({
        first_name: 'Алексей',
        last_name: 'Морозов',
        middle_name: 'Анатольевич',
        phone: '+79234447766',
    }),
    patientMock({
        first_name: 'Анастасия',
        last_name: 'Новикова',
        middle_name: 'Денисовна',
        phone: '+79001112233',
    }),
    patientMock({
        first_name: 'Сергей',
        last_name: 'Лебедев',
        middle_name: 'Алексеевич',
        phone: '+79886665544',
    }),
];
