import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLSField, setLSField } from 'helpers/localStorageHelper';
import { RootState } from 'redux/store';

export type ThemeType = 'light' | 'dark';

enum LS_ITEM {
    THEME = 'theme',
    SIDEBAR_OPENED = 'sidebar_opened',
}

export interface AppState {
    theme: Nullable<ThemeType>;
    warning?: string;
    sidebarExpanded: boolean;
}

const getCurrentTheme = (): ThemeType => getLSField(LS_ITEM.THEME) as ThemeType;
const getCurrentSidebarState = (): boolean => getLSField(LS_ITEM.SIDEBAR_OPENED) === '1';

const initialState: AppState = {
    theme: getCurrentTheme(),
    sidebarExpanded: getCurrentSidebarState(),
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        switchTheme: (state, action: PayloadAction<Nullable<ThemeType>>) => {
            const theme = action.payload ?? getCurrentTheme();
            setLSField(LS_ITEM.THEME, theme);
            state.theme = theme;
        },
        addWarning: (state, action: PayloadAction<string>) => {
            state.warning = action.payload;
        },
        clearWarning: (state) => {
            state.warning = '';
        },
        toggleSidebar: (state) => {
            const sidebarExpanded = !state.sidebarExpanded;
            setLSField(LS_ITEM.SIDEBAR_OPENED, sidebarExpanded ? '1' : '0');
            state.sidebarExpanded = sidebarExpanded;
        },
    },
});

export const { switchTheme, addWarning, clearWarning, toggleSidebar } = appSlice.actions;

export const appSelector = (state: RootState) => state.app;

export const isDarkTheme = (state: RootState) => state.app.theme === 'dark';

export const isSidebarExpanded = (state: RootState) => state.app.sidebarExpanded;

export default appSlice.reducer;
