// import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'components/shared/Card/Card';
import { ContentLayout } from '../shared/ContentLayout/ContentLayout';
import styles from './MainPage.module.scss';
import { CalendarIcon } from 'components/shared/icons/CalendarIcon';
import { StaffIcon } from 'components/shared/icons/StaffIcon';
import { PatientIcon } from 'components/shared/icons/PatientIcon';
import { ProfileIcon } from 'components/shared/icons/ProfileIcon';
import { Paths } from 'domain/Paths';
// import { useEffect } from 'react';
// import { Paths } from 'domain/Paths';

export const MainPage = () => {
    // const location = useLocation();
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (location.pathname === '/') {
    //         navigate(Paths.patients);
    //     }
    // }, [location.pathname]);

    return (
        <ContentLayout noBackground>
            <div className={styles.root}>
                <div className={styles.block_1}>
                    <Card
                        title="Календарь"
                        height={310}
                        bgIcon={<CalendarIcon scaleable />}
                        link={Paths.schedule}
                    >
                        <div className={styles.blockContent}>График работ сотрудников</div>
                    </Card>
                    <Card
                        title="Сотрудники"
                        height={310}
                        bgIcon={<StaffIcon scaleable />}
                        link={Paths.staff}
                    >
                        <div className={styles.blockContent}>Cписок сотрудников</div>
                    </Card>
                </div>
                <div className={styles.block_2}>
                    <Card
                        title="Пациенты"
                        bgColor="blue"
                        height={204}
                        bgIcon={<PatientIcon scaleable />}
                        link={Paths.patients}
                    >
                        <div className={styles.blockContent}>Реестр пациентов</div>
                    </Card>
                    <Card
                        title="Кабинет врача"
                        bgColor="grey"
                        height={204}
                        bgIcon={<ProfileIcon scaleable />}
                        link={Paths.profile}
                    >
                        <div className={styles.blockContent}>Кабинет врача</div>
                    </Card>
                    <Card title="Профиль" bgColor="dark" height={204} link={Paths.profile}>
                        <div className={styles.blockContent}>Настройки учетной записи</div>
                    </Card>
                </div>
                <div className={styles.block_3}>
                    <Card title="Мероприятия" height={400}>
                        <div className={styles.blockContent}>Мероприятия</div>
                    </Card>
                </div>
            </div>
        </ContentLayout>
    );
};
