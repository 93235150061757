import { BreadcrumbProps } from '../Breadcrumbs/Breadcrumbs';
import styles from './ContentLayout.module.scss';
import { Loader } from '../Loader/Loader';
import cx from 'clsx';
import { Card } from 'components/shared/Card/Card';
import { useEffect, useState } from 'react';
import Inputmask from 'inputmask';

interface IProps {
    breadcrumbs?: BreadcrumbProps[];
    showBackButton?: boolean;
    isLoading?: boolean;
    children?: React.ReactNode;
    className?: string;
    title?: React.ReactNode;
    contentWidth?: number;
    contentHeight?: number;
    noBackground?: boolean;
    noScroll?: boolean;
}

export const ContentLayout: React.FC<IProps> = ({
    breadcrumbs,
    showBackButton = false,
    isLoading = false,
    children,
    className = '',
    contentWidth,
    contentHeight,
    noBackground = false,
    noScroll = false,
}) => {
    const [isFakeLoading, setIsFakeLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsFakeLoading(false);
        }, 500);

        /** Маска для всех инпутов с типом tel */
        const phones = document.querySelectorAll('[type=tel]');
        phones.forEach((phone) => {
            new Inputmask('+7 999 999 99 99').mask(phone as HTMLElement);
        });
    }, []);

    return (
        <div
            className={cx({
                [styles.root]: true,
                [styles.root_centered]: !!contentWidth,
                [className]: true,
            })}
        >
            <Card
                className={styles.content}
                width={contentWidth}
                height={contentHeight}
                isScrollable={!noScroll}
                noBackground={noBackground}
            >
                <Loader isLoading={isLoading || isFakeLoading} className={styles.content__inner}>
                    {children}
                </Loader>
            </Card>
        </div>
    );
};
