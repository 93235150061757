import * as React from 'react';

interface Props {
    className?: string;
    scaleable?: boolean;
    width?: number;
    height?: number;
}

export const ArrowupIcon: React.FC<Props> = ({
    className,
    scaleable = false,
    width = 14,
    height = 14,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={scaleable ? undefined : width}
            height={scaleable ? undefined : height}
            viewBox="0 0 18 11"
            fill="none"
            className={className}
        >
            <defs>
                <clipPath id="A">
                    <path id="svg_1" d="m0,11l0,-11l18,0l0,11l-18,0z" fill="#fff" />
                </clipPath>
            </defs>
            <g id="svg_2" clipPath="url(#A)">
                <path
                    id="svg_3"
                    fill="currentColor"
                    d="m14.82,10.05738l-5.82,-6.097l-5.82,6.097c-0.585,0.613 -1.53,0.613 -2.115,0a1.62,1.62 0 0 1 0,-2.216l6.885,-7.212c0.585,-0.613 1.53,-0.613 2.115,0l6.885,7.213a1.62,1.62 0 0 1 0,2.216c-0.585,0.597 -1.545,0.613 -2.13,0l0,-0.001z"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    );
};
