import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from 'redux/api';
import appReducer from './slices/appSlice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (gDM) => gDM({ serializableCheck: false }).concat(api.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
