import * as React from 'react';
import { Toggle } from '../Toggle/Toggle';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { isDarkTheme, switchTheme } from 'redux/slices/appSlice';

interface Props {
    className?: string;
}

export const SwitchThemeToggle: React.FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch();

    const isDark = useAppSelector(isDarkTheme);

    const themeToggler = () => dispatch(switchTheme(isDark ? 'light' : 'dark'));

    const position = isDark ? 'right' : 'left';

    return <Toggle onChange={themeToggler} position={position} className={className} />;
};
