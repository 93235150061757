import { CHAIRS, SCHEDULE_PARAMS } from 'variables/constants';
import styles from './ScheduleTable.module.scss';
import cx, { clsx } from 'clsx';
import {
    IScheduleItem,
    getCurSchedule,
    getRowsapn,
    getScheduleCardHeight,
    getScheduleTimeList,
    isSpanedCell,
} from 'domain/schedule';
import { HOURS_MINUTES, formatDate } from 'utils/formatDate';
import { getFullname } from 'utils/getFullname';

interface IProps {
    scheduleItems: IScheduleItem[];
    chairs: number[];
    className?: string;
}

export const ScheduleTable: React.FC<IProps> = ({ scheduleItems, chairs, className = '' }) => {
    const timeList = getScheduleTimeList(SCHEDULE_PARAMS);

    return (
        <div className={cx({ [styles.root]: true, [className]: true })}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        {CHAIRS.map((chair) => (
                            <th key={chair}>{chair}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {timeList.map((date) => {
                        return (
                            <TimeRow
                                key={date.valueOf()}
                                date={date}
                                scheduleItems={scheduleItems}
                                chairs={chairs}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

interface ITimeRowProps {
    chairs: number[];
    scheduleItems: IScheduleItem[];
    date: Date;
}

const TimeRow: React.FC<ITimeRowProps> = ({ date, scheduleItems, chairs }) => {
    return (
        <tr>
            <td>{formatDate(date, HOURS_MINUTES)}</td>
            {chairs.map((chair) => {
                const schedule = getCurSchedule(scheduleItems, date, chair);
                const cardHeight = getScheduleCardHeight(schedule);
                const rowspan = getRowsapn(scheduleItems, date, chair);
                const isSpaned = isSpanedCell(scheduleItems, date, chair);
                if (!isSpaned) {
                    return (
                        <td
                            className={clsx({
                                [styles.cell]: true,
                                [styles.cell_filled]: !!rowspan,
                                [styles.cell_notFull]: cardHeight !== 100,
                            })}
                            rowSpan={rowspan}
                            key={chair}
                        >
                            {schedule && (
                                <ScheduleCard schedule={schedule} cardHeight={cardHeight} />
                            )}
                        </td>
                    );
                }
                return null;
            })}
        </tr>
    );
};

interface IScheduleCardProps {
    schedule: IScheduleItem;
    cardHeight: number;
}

const ScheduleCard: React.FC<IScheduleCardProps> = ({ schedule, cardHeight }) => {
    const { first_name, last_name, middle_name } = schedule.patient;
    const fullName = getFullname({
        firstName: first_name,
        lastName: last_name,
        middleName: middle_name,
    });

    const style = { height: `${cardHeight}%` };

    return (
        <div
            style={style}
            className={cx({
                [styles.scheduleCard]: true,
                [styles.scheduleCard_notFull]: cardHeight !== 100,
            })}
            title={fullName}
        >
            <div className={styles.scheduleCard__fio}>{fullName}</div>
        </div>
    );
};
