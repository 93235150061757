import { Patient } from 'api';
import { IUser } from 'redux/models/userModels';
import { getDateByString } from 'utils/formatDate';
import { SCHEDULE_PARAMS } from 'variables/constants';

export enum PatientStatusEnum {
    AcceptAwaiting = 'AcceptAwaiting',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    TreatAwaiting = 'TreatAwaiting',
    Treatment = 'Treatment',
    Completed = 'Completed',
}

export const PatientStatusDescription: Record<PatientStatusEnum, string> = {
    AcceptAwaiting: 'Ожидание подтверждения',
    Accepted: 'Пациент подтвердил, придёт',
    Rejected: 'Пациент не придёт',
    TreatAwaiting: 'Пациент пришёл (ожидает)',
    Treatment: 'Пациент на приеме',
    Completed: 'Пациент вышел',
};

interface ITime {
    hours: number;
    minutes: number;
}

export interface IScheduleParams {
    startTime: ITime;
    endTime: ITime;
    step: number;
}

export interface IScheduleItem {
    doctor: IUser;
    patient: Patient;
    start_date: string;
    end_date: string;
    chair: number;
    status: PatientStatusEnum;
    assistent?: IUser;
    visit_reason?: string;
    patient_note?: string;
    how_find?: string;
    who_reffered?: IUser;
    is_first_visit?: boolean;
    real_end_date?: string;
}

export function getScheduleTimeList({ startTime, endTime, step }: IScheduleParams) {
    const start = getDateWithTime(startTime);
    const end = getDateWithTime(endTime);
    const list: Date[] = [start];

    let current = getDateWithTime(startTime);

    while (
        list[list.length - 1].getTime() <= end.getTime() &&
        list[list.length - 1].getTime() >= start.getTime()
    ) {
        current.setMinutes(current.getMinutes() + step);
        list.push(new Date(current));
    }

    return list;
}

function getDateWithTime({ hours, minutes }: ITime) {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
}

export function matchTime(v1: Date | string, v2: Date | string) {
    const date1 = getDateByString(v1);
    const date2 = getDateByString(v2);

    const hours1 = date1.getHours();
    const hours2 = date2.getHours();
    const minutes1 = date1.getMinutes();
    const minutes2 = date2.getMinutes();
    return hours1 === hours2 && minutes1 === minutes2;
}

export function findScheduleItem(items: IScheduleItem[], currentDate: Date, currentChair: number) {
    const scheduleItem = items.find(({ chair, start_date }) => {
        return currentChair === chair && matchTime(start_date, currentDate);
    });
    return scheduleItem;
}

export function getRowsapn(
    items: IScheduleItem[],
    curDate: Date,
    curChair: number,
): number | undefined {
    const schedule = getCurSchedule(items, curDate, curChair);
    if (schedule) {
        return getStepCount(schedule.end_date) - getStepCount(schedule.start_date);
    }
    return undefined;
}

export function getCurSchedule(items: IScheduleItem[], curDate: Date, curChair: number) {
    return items.find(({ chair, start_date }) => {
        return curChair === chair && matchTime(start_date, curDate);
    });
}

/** Количество строк для переданного времени начиная с 00:00 */
function getStepCount(value: string) {
    const date = getDateByString(value);
    const hours = date.getHours();
    const stepCount = date.getMinutes() === SCHEDULE_PARAMS.step ? hours * 2 + 1 : hours * 2;
    return stepCount;
}

export function isSpanedCell(items: IScheduleItem[], cur: Date, curChair: number) {
    const isBetween = items.some(({ chair, start_date, end_date }) => {
        const start = getDateByString(start_date);
        const end = getDateByString(end_date);
        const startVal = new Date(2000, 0, 1, start.getHours(), start.getMinutes(), 0, 0).valueOf();
        const endVal = new Date(2000, 0, 1, end.getHours(), end.getMinutes(), 0, 0).valueOf();
        const curVal = new Date(2000, 0, 1, cur.getHours(), cur.getMinutes(), 0, 0).valueOf();
        return chair === curChair && curVal > startVal && curVal < endVal;
    });

    return isBetween;
}

export function getScheduleCardHeight(schedule: Nullable<IScheduleItem>) {
    if (schedule) {
        const { start_date, end_date, real_end_date } = schedule;

        if (real_end_date) {
            const start = getDateByString(start_date).valueOf();
            const end = getDateByString(end_date).valueOf();
            const real = getDateByString(real_end_date).valueOf();
            return ((real - start) / (end - start)) * 100;
        }
    }

    return 100;
}
