import { useAppSelector } from 'redux/hooks';
import styles from './Card.module.scss';
import cx from 'clsx';
import { isDarkTheme } from 'redux/slices/appSlice';
import React from 'react';

type ColorType = 'default' | 'blue' | 'dark' | 'grey';

interface IProps {
    children: React.ReactNode;
    className?: string;
    bgColor?: ColorType;
    bgIcon?: React.ReactNode;
    noPadding?: boolean;
    title?: React.ReactNode;
    width?: number;
    height?: number;
    isScrollable?: boolean;
    noBackground?: boolean;
    link?: string;
}

export const Card: React.FC<IProps> = ({
    children,
    className = '',
    bgColor = 'default',
    bgIcon,
    noPadding = false,
    title,
    width,
    height,
    isScrollable = false,
    noBackground = false,
    link,
}) => {
    const isDark = useAppSelector(isDarkTheme);

    const handleClick = (e: React.MouseEvent) => {
        if (link) {
            e.preventDefault();
            window.location.href = link;
        }
    };

    return (
        <div
            className={cx({
                [styles.root]: true,
                [styles[bgColor]]: true,
                [styles.root_noPadding]: noPadding,
                [styles.root_isScrollable]: isScrollable,
                [styles.root_noBackground]: noBackground,
                [styles.root_isLink]: !!link,
                [styles.root_isDark]: isDark,
                [className]: true,
            })}
            style={{ width, height }}
            onClick={handleClick}
        >
            {!!title && <div className={styles.title}>{title}</div>}
            <div className={styles.content}>{children}</div>
            {!!bgIcon && <div className={styles.bgIcon}>{bgIcon}</div>}
        </div>
    );
};
