import { createApi } from '@reduxjs/toolkit/query/react';
import { authEndpoints } from './endpoints/authEndpoints';
import { userEndpoints } from './endpoints/userEndpoints';
import { patientEndpoints } from './endpoints/patientEndpoints';
import { scheduleEndpoints } from './endpoints/scheduleEndpoints';
import { request } from './request';

export enum TagsEnum {
    Auth = 'Auth',
    User = 'User',
    Patient = 'Patient',
    Schedule = 'Schedule',
}

export const api = createApi({
    reducerPath: 'api',
    baseQuery: request,
    tagTypes: Object.keys(TagsEnum),
    endpoints: (builder) => ({
        ...authEndpoints(builder),
        ...userEndpoints(builder),
        ...patientEndpoints(builder),
        ...scheduleEndpoints(builder),
    }),
});

export const {
    /** Auth hooks */
    useAuthSigninMutation,

    /** User hooks */
    useLazyGetUserQuery,

    /** Patient hooks */
    useGetPatientListQuery,
    useGetPatientByIdQuery,
    useSetPatientByIdMutation,

    /** Schedule hooks */
    useGetScheduleByDateQuery,
} = api;
