import { IScheduleItem, PatientStatusEnum } from 'domain/schedule';
import { doctorListMock } from './doctorMock';
import { patientListMock } from './patientsMock';

export const SCHEDULE_ITEMS: IScheduleItem[] = [
    {
        doctor: doctorListMock[0],
        patient: patientListMock[0],
        chair: 1,
        start_date: new Date(2023, 8, 10, 9, 0).toISOString(),
        end_date: new Date(2023, 8, 10, 9, 30).toISOString(),
        status: PatientStatusEnum.AcceptAwaiting,
    },
    {
        doctor: doctorListMock[1],
        patient: patientListMock[1],
        chair: 1,
        start_date: new Date(2023, 8, 10, 9, 30).toISOString(),
        end_date: new Date(2023, 8, 10, 10, 30).toISOString(),
        status: PatientStatusEnum.Accepted,
    },
    {
        doctor: doctorListMock[2],
        patient: patientListMock[2],
        chair: 1,
        start_date: new Date(2023, 8, 10, 11, 0).toISOString(),
        end_date: new Date(2023, 8, 10, 12, 30).toISOString(),
        status: PatientStatusEnum.Completed,
        real_end_date: new Date(2023, 8, 10, 12, 10).toISOString(),
    },
    {
        doctor: doctorListMock[1],
        patient: patientListMock[1],
        chair: 1,
        start_date: new Date(2023, 8, 10, 12, 30).toISOString(),
        end_date: new Date(2023, 8, 10, 14, 30).toISOString(),
        status: PatientStatusEnum.Rejected,
    },
    {
        doctor: doctorListMock[3],
        patient: patientListMock[3],
        chair: 2,
        start_date: new Date(2023, 8, 10, 8, 30).toISOString(),
        end_date: new Date(2023, 8, 10, 11, 0).toISOString(),
        status: PatientStatusEnum.TreatAwaiting,
    },
    {
        doctor: doctorListMock[3],
        patient: patientListMock[3],
        chair: 2,
        start_date: new Date(2023, 8, 10, 11, 30).toISOString(),
        end_date: new Date(2023, 8, 10, 13, 0).toISOString(),
        status: PatientStatusEnum.Treatment,
    },
    {
        doctor: doctorListMock[4],
        patient: patientListMock[4],
        chair: 2,
        start_date: new Date(2023, 8, 10, 13, 0).toISOString(),
        end_date: new Date(2023, 8, 10, 15, 30).toISOString(),
        status: PatientStatusEnum.Completed,
        real_end_date: new Date(2023, 8, 10, 14, 45).toISOString(),
    },
];
