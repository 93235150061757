export const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const guid = () => {
    const min = 1000;
    const max = 99999999;
    return Math.floor(Math.random() * (max - min + 1) + min);
};
