import * as React from 'react';

interface Props {
    className?: string;
    scaleable?: boolean;
    width?: number;
    height?: number;
}

export const LeftArrowIcon: React.FC<Props> = ({
    className,
    scaleable = false,
    width = 25,
    height = 25,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={scaleable ? undefined : width}
            height={scaleable ? undefined : height}
            viewBox="0 0 30 30"
            fill="none"
            className={className}
        >
            <path
                d="M30 13.125H7.125l10.5-10.5L15 0 0 15l15 15 2.625-2.625-10.5-10.5H30v-3.75z"
                fill="currentColor"
            />
        </svg>
    );
};
