import * as React from 'react';

interface Props {
    className?: string;
    scaleable?: boolean;
    width?: number;
    height?: number;
}

export const AddIcon: React.FC<Props> = ({
    className,
    scaleable = false,
    width = 20,
    height = 20,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={scaleable ? undefined : width}
            height={scaleable ? undefined : height}
            viewBox="0 0 20 20"
            fill="none"
            className={className}
        >
            <path
                d="M15.656 8.859h-4.922V3.938A.94.94 0 0 0 9.797 3a.94.94 0 0 0-.937.938v4.922H3.938A.94.94 0 0 0 3 9.797a.94.94 0 0 0 .938.938h4.922v4.922a.94.94 0 0 0 .938.938.94.94 0 0 0 .938-.938v-4.922h4.922a.94.94 0 0 0 .938-.938.94.94 0 0 0-.938-.937z"
                fill="currentColor"
            />
        </svg>
    );
};
