import { RegisterOptions } from 'react-hook-form';
import { EMAIL_REGEX } from 'variables/regexVariables';

export const EMAIL_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите e-mail',
    },
    pattern: {
        value: EMAIL_REGEX,
        message: 'Некорректный формат почты',
    },
};

export const SIGNIN_PASSWORD_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите пароль',
    },
};

export const SIGNUP_PASSWORD_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите пароль',
    },
    minLength: {
        value: 8,
        message: 'Длина пароля должна быть не менее 8 символов',
    },
    maxLength: {
        value: 20,
        message: 'Длина пароля должна быть не более 20 символов',
    },
};

export const FIRSTNAME_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите имя',
    },
    pattern: {
        value: /[а-яА-Я- ёЁ]/,
        message: 'Допускаются только кириллические символы',
    },
    minLength: {
        value: 3,
        message: 'Имя должно состоять из 3-х и более букв',
    },
    maxLength: {
        value: 20,
        message: 'Превышен лимит по длине',
    },
};

export const LASTNAME_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите фамилию',
    },
    pattern: {
        value: /[а-яА-Я- ёЁ]/,
        message: 'Допускаются только кириллические символы',
    },
    minLength: {
        value: 3,
        message: 'Фамилия должно состоять из 3-х и более букв',
    },
    maxLength: {
        value: 20,
        message: 'Превышен лимит по длине',
    },
};

export const MIDDLENAME_VALIDATE: RegisterOptions = {
    pattern: {
        value: /[а-яА-Я- ёЁ]/,
        message: 'Допускаются только кириллические символы',
    },
    minLength: {
        value: 3,
        message: 'Фамилия должно состоять из 3-х и более букв',
    },
    maxLength: {
        value: 20,
        message: 'Превышен лимит по длине',
    },
};

export const ADDRESS_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите адрес',
    },
};

export const PHONE_VALIDATE: RegisterOptions = {
    pattern: {
        value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
        message: 'Не верный формат номера',
    },
};

export const INN_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите ИНН',
    },
    validate: {
        onlyDigits: (inn: string) => /\d/.test(inn) || 'ИНН должен состоять из цифр',
        correctFormat: (inn: string) => {
            const innDigits = inn.split('').map((c) => parseInt(c, 10));

            function innControlNumber(weights: number[], digits: number[]): number {
                const weighted = weights.map((w, k) => w * digits[k] || 0);
                const sum = weighted.reduce((total, x) => total + x);
                return (sum % 11) % 10;
            }

            if (innDigits.length === 10) {
                const weights = [2, 4, 10, 3, 5, 9, 4, 6, 8];
                if (innControlNumber(weights, innDigits) === innDigits[9]) {
                    return true;
                }
                return 'Некорректный ИНН';
            } else if (innDigits.length === 12) {
                const weights1 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
                const weights2 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
                if (
                    innControlNumber(weights1, innDigits) === innDigits[11] &&
                    innControlNumber(weights2, innDigits) === innDigits[10]
                ) {
                    return true;
                }
                return 'Некорректный ИНН';
            } else {
                return 'ИНН может состоять из 10 или 12 цифр';
            }
        },
    },
};

export const IIN_VALIDATE: RegisterOptions = {
    required: {
        value: true,
        message: 'Укажите ИИН',
    },
    validate: {
        onlyDigits: (iin: string) => /\d/.test(iin) || 'ИИН должен состоять из цифр',
        digitsCount: (iin) => {
            return iin.length === 12 || 'ИИН может состоять из 12 цифр';
        },
    },
};
