import * as React from 'react';

interface Props {
    className?: string;
}

export const ArrowLeftIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            className={className}
        >
            <path
                fillRule="evenodd"
                d="M10.544 3.18L4.447 9l6.097 5.82c.613.585.613 1.53 0 2.115a1.62 1.62 0 0 1-2.216 0L1.116 10.05c-.613-.585-.613-1.53 0-2.115L8.329 1.05a1.62 1.62 0 0 1 2.216 0c.597.585.613 1.545 0 2.13z"
                fill="currentColor"
            />
        </svg>
    );
};
