export enum USER_ROLES {
    ADMIN = 'ADMIN',
    DOCTOR = 'DOCTOR',
    ASSISTENT = 'ASSISTENT',
    EMPLOYEE = 'EMPLOYEE',
}

enum ResponseStatus {
    OK = 'OK',
    ERROR = 'ERROR',
}

export interface IAuthSignupRequest {
    username: string;
    email: string;
    password: string;
    roles?: USER_ROLES[];
}

export interface IAuthSignupResponse {
    status: ResponseStatus;
    username: string;
    email: string;
    roles: USER_ROLES[];
}

export interface IAuthSigninRequest {
    email: string;
    password: string;
}

export interface IAuthSigninResponse {
    accessToken: string;
}
