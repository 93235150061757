import { ContentLayout } from 'components/shared/ContentLayout/ContentLayout';
import styles from './PatientDetailPage.module.scss';
import { CardTitle } from 'components/shared/CardTitle/CardTitle';
import { Input } from 'components/shared/Input/Input';
import {
    ADDRESS_VALIDATE,
    EMAIL_VALIDATE,
    FIRSTNAME_VALIDATE,
    INN_VALIDATE,
    LASTNAME_VALIDATE,
    MIDDLENAME_VALIDATE,
} from 'utils/validate';
import {
    ADDRESS_PLACEHOLDER,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_PLACEHOLDER,
    INN_PLACEHOLDER,
    LAST_NAME_PLACEHOLDER,
    MIDDLE_NAME_PLACEHOLDER,
    PHONE_PLACEHOLDER,
} from 'variables/fieldVariables';
import { useForm } from 'react-hook-form';
import { Button } from 'components/shared/Button/Button';
import { useEffect } from 'react';
import { Paths } from 'domain/Paths';
import { useParams } from 'react-router-dom';
import { useGetPatientByIdQuery, useSetPatientByIdMutation } from 'redux/api';
import { Patient } from 'api';
import { useToast } from 'components/shared/Toast/Toast';

export const PatientDetailPage = () => {
    const { patientId = '' } = useParams<{ patientId: string }>();

    const { data, isLoading: isPatientLoading } = useGetPatientByIdQuery(patientId);

    const [updatePatient, { isLoading: isUpdateLoading }] = useSetPatientByIdMutation();

    const isLoading = isPatientLoading || isUpdateLoading;

    const { push, toast } = useToast();

    const { register, watch, handleSubmit, setValue, formState } = useForm<Patient>();

    const { errors } = formState;

    useEffect(() => {
        if (data) {
            setValue('first_name', data.first_name);
            setValue('last_name', data.last_name);
            setValue('middle_name', data.middle_name);
            setValue('phone', data.phone || '');
            setValue('address', data.address);
            setValue('birthday', data.birthday);
            setValue('email', data.email);
            setValue('inn', data.inn || '');
        }
    }, [setValue, data]);

    const onSubmit = (data: Patient) => {
        if (patientId) {
            updatePatient({ id: patientId, body: data })
                .unwrap()
                .then(() => {
                    push('Данные успешно сохранены!');
                })
                .catch(() => {
                    push('Ошибка при сохранении данных!');
                });
        }
    };

    return (
        <ContentLayout isLoading={isLoading}>
            {toast}
            <CardTitle backLink={Paths.patients}>Пациент</CardTitle>
            <div className={styles.root}>
                <form className={styles.from} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className={styles.block_1}>
                        <div className={styles.block_1__left}>
                            <Input
                                type="text"
                                register={register('last_name', LASTNAME_VALIDATE)}
                                placeholder={LAST_NAME_PLACEHOLDER}
                                watchedValue={watch('last_name')}
                                width={300}
                                error={errors.last_name}
                                required
                            />
                            <Input
                                type="text"
                                register={register('first_name', FIRSTNAME_VALIDATE)}
                                placeholder={FIRST_NAME_PLACEHOLDER}
                                watchedValue={watch('first_name')}
                                width={300}
                                error={errors.first_name}
                                required
                            />
                            <Input
                                type="text"
                                register={register('middle_name', MIDDLENAME_VALIDATE)}
                                placeholder={MIDDLE_NAME_PLACEHOLDER}
                                watchedValue={watch('middle_name') || ''}
                                width={300}
                                error={errors.middle_name}
                                required
                            />
                        </div>
                        <div className={styles.block_1__right}>
                            <Input
                                type="tel"
                                register={register('phone')}
                                placeholder={PHONE_PLACEHOLDER}
                                watchedValue={watch('phone') || ''}
                                width={300}
                                error={errors.phone}
                                required
                            />
                            <Input
                                type="text"
                                register={register('email', EMAIL_VALIDATE)}
                                placeholder={EMAIL_PLACEHOLDER}
                                watchedValue={watch('email') || ''}
                                width={300}
                                error={errors.email}
                                required
                            />
                            <Input
                                type="text"
                                register={register('inn', INN_VALIDATE)}
                                placeholder={INN_PLACEHOLDER}
                                watchedValue={watch('inn') || ''}
                                width={300}
                                error={errors.inn}
                                required
                            />
                        </div>
                    </div>
                    <div className={styles.block_2}>
                        <Input
                            type="text"
                            register={register('address', ADDRESS_VALIDATE)}
                            placeholder={ADDRESS_PLACEHOLDER}
                            watchedValue={watch('address') || ''}
                            width={730}
                            error={errors.address}
                            required
                        />
                    </div>
                    <div className={styles.bottomPanel}>
                        <Button type="submit" width={210}>
                            Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        </ContentLayout>
    );
};
