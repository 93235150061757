import { ContentLayout } from 'components/shared/ContentLayout/ContentLayout';
import styles from './SchedulePage.module.scss';
import cx from 'clsx';
import { Calendar } from 'components/shared/Calendar/Calendar';
import { useState } from 'react';
import { ScheduleTable } from './ScheduleTable';
import { formatDate, MONTH_NAME_DATE_FORMAT } from 'utils/formatDate';
import { SCHEDULE_ITEMS } from 'mock/scheduleMock';
import { CHAIRS } from 'variables/constants';
import { useGetScheduleByDateQuery } from 'redux/api';

interface IProps {
    className?: string;
}

export const SchedulePage: React.FC<IProps> = ({ className = '' }) => {
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const handleChangeDate = (date: Date) => {
        setCurrentDate(date);
    };

    const { data, isLoading } = useGetScheduleByDateQuery({
        date: currentDate.toISOString(),
    });

    const scheduleItems = (data && data.results) || SCHEDULE_ITEMS;

    return (
        <ContentLayout
            className={cx({ [styles.root]: true, [className]: true })}
            isLoading={isLoading}
        >
            <div className={styles.wrapper}>
                <Calendar
                    onChange={handleChangeDate}
                    date={currentDate}
                    className={styles.calendar}
                />
                <div className={styles.schedule}>
                    <div className={styles.dateTitle}>
                        {formatDate(currentDate, MONTH_NAME_DATE_FORMAT)}
                    </div>
                    <ScheduleTable scheduleItems={scheduleItems} chairs={CHAIRS} />
                </div>
            </div>
        </ContentLayout>
    );
};
