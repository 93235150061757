import * as React from 'react';

interface Props {
    className?: string;
    scaleable?: boolean;
    width?: number;
    height?: number;
}

export const PatientIcon: React.FC<Props> = ({
    className,
    scaleable = false,
    width = 24,
    height = 24,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={scaleable ? undefined : width}
            height={scaleable ? undefined : height}
            viewBox="0 0 24 24"
            fill="currentColor"
            fillRule="evenodd"
            className={className}
        >
            <path d="M6.253 10.686c-1.271.241-2.453.882-3.38 1.85C1.674 13.789 1 15.489 1 17.261v3.867C1 22.714 2.231 24 3.75 24h16.5c1.519 0 2.75-1.286 2.75-2.872v-3.867c0-1.772-.674-3.472-1.874-4.725-.927-.968-2.108-1.608-3.38-1.85.429-.899.67-1.912.67-2.984C18.417 4.003 15.541 1 12 1S5.583 4.003 5.583 7.702c0 1.072.241 2.085.67 2.984zm10.238 1.8c-1.157 1.186-2.744 1.918-4.492 1.918s-3.335-.731-4.492-1.918c-.037.005-.073.007-.11.007-1.211 0-2.372.503-3.228 1.397s-1.337 2.107-1.337 3.371v3.867c0 .528.411.957.917.957h16.5c.506 0 .917-.429.917-.957v-3.867c0-1.264-.481-2.477-1.337-3.371s-2.017-1.397-3.228-1.397c-.037 0-.073-.002-.11-.007zM12 2.915c2.53 0 4.583 2.146 4.583 4.787S14.53 12.489 12 12.489s-4.583-2.145-4.583-4.787S9.47 2.915 12 2.915zm4.667 14.752h-.833c-.46 0-.833.373-.833.833s.373.833.833.833h.833v.833c0 .46.373.833.833.833s.833-.373.833-.833v-.833h.833c.46 0 .833-.373.833-.833s-.373-.833-.833-.833h-.833v-.833c0-.46-.373-.833-.833-.833s-.833.373-.833.833v.833z" />
        </svg>
    );
};
