import { useAppSelector } from 'redux/hooks';
import { ProfileIcon } from '../icons/ProfileIcon';
import styles from './AvatarMini.module.scss';
import { isDarkTheme } from 'redux/slices/appSlice';
import cx from 'clsx';

interface IProps {
    name?: string;
    src?: string;
    size?: 'small' | 'medium' | 'large' | 'extralarge';
    className?: string;
}

export const AvatarMini: React.FC<IProps> = ({ name, src, size = 'medium', className = '' }) => {
    const isDark = useAppSelector(isDarkTheme);

    return (
        <div
            className={cx({
                [styles.root]: true,
                [styles[size]]: true,
                [styles.root_isDark]: isDark,
                [className]: true,
            })}
            title={name}
        >
            {!!src ? (
                <div className={styles.root__avatar}>
                    <img src={src} alt={name} />
                </div>
            ) : name ? (
                <div className={styles.root__initials}>{getNameInitials(name)}</div>
            ) : (
                <ProfileIcon className={styles.root__placeholder} />
            )}
        </div>
    );
};

function getNameInitials(value: string) {
    const name = value.trim().toUpperCase();
    const nameArr = name.split(' ');
    let initials = nameArr.length > 1 ? `${nameArr[0][0]}${nameArr[1][0]}` : name[0];
    return initials;
}
