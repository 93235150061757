import { ContentLayout } from 'components/shared/ContentLayout/ContentLayout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthSigninMutation } from 'redux/api';
import { useAuthInfo } from 'hooks/useAuthInfo';
import styles from './AuthorizationPage.module.scss';
import cx from 'clsx';
import { Input } from 'components/shared/Input/Input';
import { Button } from 'components/shared/Button/Button';
import { EMAIL_PLACEHOLDER, PASS_PLACEHOLDER } from 'variables/fieldVariables';
import { useForm } from 'react-hook-form';
import { EMAIL_VALIDATE, SIGNIN_PASSWORD_VALIDATE } from 'utils/validate';
import { ProfileIcon } from 'components/shared/icons/ProfileIcon';
import { CardTitle } from 'components/shared/CardTitle/CardTitle';
import { isDarkTheme } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/hooks';

interface IFormState {
    email: string;
    password: string;
}

export const AuthorizationPage = () => {
    const { register, handleSubmit, formState, watch } = useForm<IFormState>();

    const { login } = useAuthInfo();

    const isDark = useAppSelector(isDarkTheme);

    const { errors } = formState;

    const [responseError, setResponseError] = useState('');

    const [signin, { isLoading }] = useAuthSigninMutation();

    const navigate = useNavigate();

    const onSubmit = (data: IFormState) => {
        signin(data)
            .unwrap()
            .then(({ access, refresh }) => {
                setResponseError('');

                if (access) {
                    login({ access, refresh });
                }

                navigate({ pathname: '/' });
            })
            .catch((err) => {
                setResponseError(err.message);
            });
    };

    return (
        <ContentLayout isLoading={isLoading} contentWidth={914} contentHeight={600}>
            <div className={styles.root}>
                <div className={styles.root__form}>
                    <CardTitle>Авторизация</CardTitle>
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className={styles.form__fields}>
                            <Input
                                type="email"
                                placeholder={EMAIL_PLACEHOLDER}
                                error={errors.email}
                                required
                                register={register('email', EMAIL_VALIDATE)}
                                width={300}
                                watchedValue={watch('email')}
                            />
                            <Input
                                type="password"
                                placeholder={PASS_PLACEHOLDER}
                                error={errors.password}
                                required
                                register={register('password', SIGNIN_PASSWORD_VALIDATE)}
                                width={300}
                                watchedValue={watch('password')}
                            />
                        </div>
                        <div className={styles.errors}>{responseError}</div>
                        <div className={styles.bottomPanel}>
                            <Button type="submit" use="transparent" width={210}>
                                Войти
                            </Button>
                            <Button type="button" use="link">
                                Забыли пароль?
                            </Button>
                        </div>
                    </form>
                </div>
                <div className={styles.bgIcon}>
                    <ProfileIcon
                        width={240}
                        height={240}
                        className={cx({ [styles.bgIcon_isDark]: isDark })}
                    />
                </div>
            </div>
        </ContentLayout>
    );
};
