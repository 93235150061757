import dayjs from 'dayjs';
import localRu from 'dayjs/locale/ru';

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY, HH:mm';
export const MONTH_NAME_DATE_FORMAT = 'D MMMM YYYY';
export const HOURS_MINUTES = 'HH:mm';

export function formatDate(date: string | Date, format = DEFAULT_DATE_FORMAT): string {
    return dayjs(date).locale(localRu).format(format);
}

export function getDateByString(date: string | Date): Date {
    return dayjs(date).toDate();
}

// export const isEkbWorkTime = () => {
//     const hours = +new Date()
//         .toLocaleTimeString('ru-RU', { timeZone: 'Asia/Yekaterinburg' })
//         .split(':')[0];
//     return hours >= 9 && hours < 18;
// };
