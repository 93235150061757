import { ContentLayout } from 'components/shared/ContentLayout/ContentLayout';
import { Paths } from 'domain/Paths';
import styles from './PatientsPage.module.scss';
import { Button } from 'components/shared/Button/Button';
import { CardTitle } from 'components/shared/CardTitle/CardTitle';
import { PatientsTable } from './PatientsTable';
import { AddIcon } from 'components/shared/icons/AddIcon';
import { useGetPatientListQuery } from 'redux/api';

export const PatientsPage = () => {
    const { data, isLoading } = useGetPatientListQuery({ page: 1 });

    const patientList = data?.results || [];

    return (
        <ContentLayout noScroll isLoading={isLoading}>
            <CardTitle>Пациенты</CardTitle>
            <PatientsTable items={patientList} />
            <Button
                to={Paths.newPatient}
                className={styles.addPatientButton}
                icon={<AddIcon />}
                use="transparent"
            >
                Добавить пациента
            </Button>
        </ContentLayout>
    );
};
