import { User } from 'api';
import { TagsEnum, api } from 'redux/api';
import { store } from 'redux/store';
import { BuildType } from 'redux/types';

export const userEndpoints = (builder: BuildType) => {
    return {
        /** Запрос пользователя */
        getUser: builder.query<User, void>({
            query: () => ({ url: '/auth/user/me/', method: 'GET' }),
            providesTags: [TagsEnum.User],
        }),
    };
};

export function clearUserData() {
    store.dispatch(api.util.resetApiState());
}
