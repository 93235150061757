import { isDarkTheme } from 'redux/slices/appSlice';
import styles from './CardTitle.module.scss';
import cx from 'clsx';
import { useAppSelector } from 'redux/hooks';
import { LeftArrowIcon } from '../icons/LeftArrowIcon';
import { Link } from '../Link/Link';

interface IProps {
    children: React.ReactNode;
    backLink?: string;
    className?: string;
}

export const CardTitle: React.FC<IProps> = ({ children, backLink, className = '' }) => {
    const isDark = useAppSelector(isDarkTheme);

    return (
        <div
            className={cx({
                [styles.root]: true,
                [styles.root_isDark]: isDark,
                [className]: true,
            })}
        >
            {!!backLink && (
                <Link href={backLink} className={styles.backLink}>
                    <LeftArrowIcon />
                </Link>
            )}
            <div>{children}</div>
        </div>
    );
};
