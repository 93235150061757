import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import styles from './Select.module.scss';
import cx from 'clsx';
import { ArrowdownIcon } from '../icons/ArrowdownIcon';
import { ArrowupIcon } from '../icons/ArrowupIcon';

export type OptionType = { title: string; value: string | number };

interface IProps {
    selected: OptionType | null;
    options: OptionType[];
    placeholder?: string;
    mode?: 'rows' | 'cells';
    status?: 'default' | 'invalid';
    width?: number;
    className?: string;
    onChange?: (selected: OptionType['value']) => void;
    onClose?: () => void;
}

export const Select: React.FC<IProps> = ({
    mode = 'rows',
    options,
    placeholder,
    status = 'default',
    selected,
    width,
    className = '',
    onChange,
    onClose,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const rootRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const { target } = event;
            if (target instanceof Node && !rootRef.current?.contains(target)) {
                isOpen && onClose?.();
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        const placeholderEl = placeholderRef.current;
        if (!placeholderEl) return;

        const handleClick = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                setIsOpen((prev) => !prev);
            }
        };

        placeholderEl.addEventListener('keydown', handleClick);

        return () => {
            placeholderEl.removeEventListener('keydown', handleClick);
        };
    }, []);

    const handleOptionClick = (value: OptionType['value']) => {
        setIsOpen(false);
        onChange?.(value);
    };

    const handlePlaceHolderClick: MouseEventHandler<HTMLDivElement> = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div
            className={cx({ [styles.root]: true, [styles.root_isOpen]: isOpen, [className]: true })}
            ref={rootRef}
            data-is-active={isOpen}
            data-mode={mode}
            style={{ width }}
        >
            <div
                className={styles.root__placeholder}
                data-status={status}
                data-selected={!!selected?.value}
                role="button"
                tabIndex={0}
                ref={placeholderRef}
                onClick={handlePlaceHolderClick}
            >
                {selected?.title || placeholder}
                <div className={styles.root__arrow}>
                    {isOpen ? (
                        <ArrowupIcon width={10} height={10} />
                    ) : (
                        <ArrowdownIcon width={10} height={10} />
                    )}
                </div>
            </div>
            {isOpen && (
                <ul className={styles.root__select}>
                    {options.map((option) => (
                        <Option key={option.value} option={option} onClick={handleOptionClick} />
                    ))}
                </ul>
            )}
        </div>
    );
};

interface IOptionProps {
    option: OptionType;
    onClick: (value: OptionType['value']) => void;
}

const Option: React.FC<IOptionProps> = ({ option, onClick }) => {
    const { value, title } = option;
    const optionRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        const option = optionRef.current;
        if (!option) return;

        const handleEnterPress = (event: KeyboardEvent) => {
            if (document.activeElement === option && event.key === 'Enter') {
                onClick(value);
            }
        };

        option.addEventListener('keydown', handleEnterPress);

        return () => {
            option.removeEventListener('keydown', handleEnterPress);
        };
    }, [value, onClick]);

    const handleClick =
        (clickedValue: OptionType['value']): MouseEventHandler<HTMLLIElement> =>
        () => {
            onClick(clickedValue);
        };

    return (
        <li
            className={styles.root__option}
            value={value}
            onClick={handleClick(value)}
            tabIndex={0}
            ref={optionRef}
        >
            {title}
        </li>
    );
};
